import React, { useState, useEffect } from "react"
import Layout from "../share/layout"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Modal from 'react-bootstrap/Modal'
import arrow_next_min from '../../images/arrow_next-min.png'
import arrow_red_back from '../../images/arrow_red.png'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ControlHomePREV from '../../images/arrow_prev.png'
import ControlHomeNEXT from '../../images/arrow_next.png'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Link } from 'gatsby'
import Error404IMG from '../../images/ERROR.jpg'
import Image from 'gatsby-image';
import ShareButton from "../share/ShareButton"
import { useLocation } from "@reach/router"

const SliderType2Template = ({ pageContext }) => {
	let location = useLocation();

	var normalize = (function () {
		var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç,",
			to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
			mapping = {};

		for (var i = 0, j = from.length; i < j; i++)
			mapping[from.charAt(i)] = to.charAt(i);

		return function (str) {
			var ret = [];
			for (var i = 0, j = str.length; i < j; i++) {
				var c = str.charAt(i);
				if (mapping.hasOwnProperty(str.charAt(i)))
					ret.push(mapping[c]);
				else if ((str[i] === " "))
					ret.push('-');
				else if ((str.length - 1 === i) && (str[str.length - 1] === " "))
					ret.push('-');
				else
					ret.push(c);
			}
			return ret.join('');
		}
	})();

	const [ShowButtons, setShowButtons] = useState();

	let imgData;
	useEffect(() => {
		if (pageContext.data.videoDeFondo) {
			setShowButtons(false)
		} else if (pageContext.data.imagenesDeFondo.length === 1) {
			setShowButtons(false)
		} else {
			setShowButtons(true)
		}

	}, [pageContext.data.imagenesDeFondo, pageContext.data.videoDeFondo])

	const play = () => {
		if (document.querySelector('[aria-label="Play"]')) {
			document.querySelector('[aria-label="Play"]').click()
		}
	}

	const pause = () => {
		if (document.querySelector('[aria-label="Pause"]')) {
			document.querySelector('[aria-label="Pause"]').click()
		}
	}

	const RichContent = pageContext.data.descripcion.json;
	const RichContentModal = pageContext.data.descripcionEnModal.json;


	const clickSliderPREV = () => {
		document.querySelector('.control-prev').click()
	}

	const clickSliderNEXT = () => {
		document.querySelector('.control-next').click()
	}

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	if (pageContext.data.imagenDeFondoDeVideo === false) {
		imgData = pageContext.data.imagenesDeFondo[0].file.url
	} else {
		imgData = pageContext.data.fotoParaMostrarEnElMarco.file.url
	}


	return (
		<Layout
			descritpion={pageContext.data.subtitulo}
			title={pageContext.data.titulo}
			subtitile={pageContext.data.subtitulo}
			imgurl={imgData}
			metaTitle={pageContext.data.metaTitle}
			metaKeywords={pageContext.data.metaKeywords}
			metaDescripcin={pageContext.data.metaDescripcin}
		>
			<div className="exposicion-slider-container">
				{
					pageContext.data.tieneMusica ?
						<div className="control-play-pause-music">
							<div className="control-background">
								<div className="control-container">
									<button onClick={play}>
										<i className="fa fa-play"></i>
									</button>
									<button id="button-pause" onClick={pause}>
										<i className="fa fa-pause"></i>
									</button>
								</div>
							</div>
						</div> : null

				}
				{pageContext.data.musica ?
					<AudioPlayer style={{ 'display': 'none' }}
						src={pageContext.data.musica.file.url}
					/> : null

				}

				<ShareButton url={location.pathname} />

				<div className="button-back">
					<button>
						<img src={arrow_red_back} alt="Flecha de retroceso" />
						<Link to={`/galeria/${normalize(pageContext.data.idParaEnlazar).toLocaleLowerCase().trim()}`}><p>VOLVER AL INICIO</p></Link>
					</button>
				</div>
				{ShowButtons &&
					<button onClick={clickSliderPREV} className="button-control-prev"><img alt="Flecha de retroceso" className="button-control-prev-icon" src={ControlHomePREV} /></button>
				}
				{ShowButtons &&
					<button onClick={clickSliderNEXT} className="button-control-next"><img alt="Flecha de siguiente" className="button-control-next-icon" src={ControlHomeNEXT} /></button>
				}
				<div className="outside-element-slider-container">
					<div className="carousel-caption carousel-edit">
						<div className="marco-container">
							<div className="img-full-container">
								<div className="marco-container-title">
									<div className="img-container-tittle">
										<img alt="Flecha de siguiente" src={arrow_next_min} />
									</div>
									<p>{pageContext.data.textoSobreMarco}</p>
								</div>
								{
									pageContext.data.existeVideoEnElMarco ?
										<div className="marco" onClick={handleShow} role="button" tabIndex="0" onKeyDown={(ev) => { if (ev.keyCode === 13) { handleShow() } }} style={{ 'cursor': 'pointer' }}>
											<div className="circle-container"><i className="fa fa-play-circle"></i></div>
											<Image className="img-marco" fluid={pageContext.data.fotoParaMostrarEnElMarco.fluid} alt={pageContext.data.fotoParaMostrarEnElMarco.description ? pageContext.data.fotoParaMostrarEnElMarco.description : pageContext.data.fotoParaMostrarEnElMarco.title} />
										</div> :
										<div className="marco">
											<Image className="img-marco" fluid={pageContext.data.fotoParaMostrarEnElMarco.fluid} alt={pageContext.data.fotoParaMostrarEnElMarco.description ? pageContext.data.fotoParaMostrarEnElMarco.description : pageContext.data.fotoParaMostrarEnElMarco.title} />
										</div>
								}
							</div>
							<div className="carousel-caption-tittle-container">
								<div>
									<h3>{pageContext.data.titulo}</h3>
									<h2>{pageContext.data.subtitulo}</h2>
								</div>
							</div>
						</div>
						<div className="carousel-caption-text-container">
							<div className="carousel-caption-tittle-container">
								<div>
									<h1>{pageContext.data.titulo}</h1>
									<h2>{pageContext.data.subtitulo}</h2>
								</div>
							</div>
							<div className="vertical_line"></div>
							<div className="carousel-caption-subtittle-container">
								<div className="carusel-sub-container">
									{documentToReactComponents(RichContent)}
								</div>
							</div>
						</div>
					</div>
				</div>

				{
					pageContext.data.existeVideoEnElMarco ?
						<Modal show={show} onHide={handleClose}>
							<Modal.Header closeButton>
								<Modal.Title className="modal-title-video">{documentToReactComponents(RichContentModal)}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<iframe width="100%" height="100%" src={pageContext.data.urlDelVideo} title={pageContext.data.titulo}></iframe>
							</Modal.Body>
						</Modal> : null
				}
				{pageContext.data.imagenDeFondoDeVideo ?
					<Carousel showThumbs={false}>
						<div>
							<div className="img-exposion-gradient-container">
							</div>
							<video autoPlay muted loop id="myVideo" className="img-exposicion-container">
								<source src={pageContext.data.videoDeFondo.file.url} type="video/mp4" />
							</video>
						</div>
					</Carousel>

					:

					<React.Fragment>
						{pageContext.data.imagenesDeFondo ?
							<React.Fragment>
								{
									pageContext.data.imagenesDeFondo.length === 1 ?
										<Carousel interval={1000} transitionTime={1000} stopOnHover={false} showThumbs={false}>
											<div>
												<div className="img-exposion-gradient-container">
												</div>
												<Image className="img-exposicion-container" fluid={pageContext.data.imagenesDeFondo[0].fluid} />
											</div>
										</Carousel>
										:
										<Carousel autoPlay infiniteLoop interval={8000} transitionTime={1000} stopOnHover={false} showThumbs={false}>
											{pageContext.data.imagenesDeFondo.map((imagenes, i) => (
												<div key={100 + i}>
													{

														<div>
															<div className="img-exposion-gradient-container">
															</div>
															<Image className="img-exposicion-container" fluid={imagenes.fluid} />
														</div>
													}
												</div>
											))
											}
										</Carousel>
								}
							</React.Fragment> :
							<div>
								<img
									className="img-exposicion-container"
									src={Error404IMG}
									alt="First slide"></img>
								<div className="carousel-control-next"></div>
								<div className="carousel-control-prev"></div>
							</div>

						}
					</React.Fragment>
				}
			</div>
		</Layout>
	)
}
export default SliderType2Template
